<template>
  <el-dialog :title="isEdit ? '编辑' : '新增'" width="500px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="90px" :rules="rules">
      <el-form-item label="项目类型" prop="projectType">
        <el-radio-group v-model="form.projectType">
          <el-radio label="Maven">Maven</el-radio>
          <el-radio label="Gradle">Gradle</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签">
        <div class="flex-c">
          <el-select v-model="form.labelIds" multiple clearable @change="labelChange" style="width: 100%" class="mr10">
            <el-option v-for="item in labelList" :key="item.id" :value="item.id" :label="item.label"></el-option>
          </el-select>
          <el-button class="ml10" plain type="primary" @click="addLabel()">新增</el-button>
        </div>
      </el-form-item>
      <el-form-item label="Name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Group" prop="groupId">
        <el-input v-model="form.groupId" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Artifact" prop="artifactId">
        <el-input v-model="form.artifactId" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="version" prop="version">
        <el-input v-model="form.version" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Description">
        <el-input v-model="form.description" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="scope">
        <el-select v-model="form.scope" clearable style="width: 100%">
          <el-option v-for="(item, index) in mavenConstant.scope" :key="index" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="type">
        <el-select v-model="form.type" clearable style="width: 100%">
          <el-option v-for="(item, index) in mavenConstant.type" :key="index" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="optional">
        <el-select v-model="form.optional" clearable style="width: 100%">
          <el-option v-for="(item, index) in mavenConstant.optional" :key="index" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="classifier">
        <el-select v-model="form.classifier" clearable style="width: 100%">
          <el-option v-for="(item, index) in mavenConstant.classifier" :key="index" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
    <el-drawer title="标签管理" :visible.sync="lableDrawer" :append-to-body="true" size="85%" direction="rtl" @close="getLabel()">
      <lableMgt></lableMgt>
    </el-drawer>
  </el-dialog>
</template>
<script>
import Api from '@/api/devops/relyMgt'
import commonApi from '@/api/devops/common'
import lableMgt from '../../common/labelMgt.vue'
export default {
  components: { lableMgt },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
    thirdPart: { type: Number, default: 0 },
    detail: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.getLabel()
        this.getMavenConstant()
        this.$refs['form']?.resetFields()
      }
    },
    detail(val) {
      this.isEdit = !_.isEmpty(val)
      if (this.isEdit) {
        this.form = { ...val }
        let labelIds = val.labels.map((i) => i.id)
        this.$set(this.form, 'labelIds', labelIds)
      } else {
        this.form = {
          projectType: 'Maven',
          versions: [],
        }
      }
    },
  },
  data() {
    return {
      form: {},
      lableDrawer: false,
      labelList: [],
      typeList: [{ name: 'jar' }, { name: 'war' }, { name: 'pom' }],
      scopeList: [{ name: 'import' }, { name: 'compile' }, { name: 'runtime' }, { name: 'test' }, { name: 'system' }, { name: 'provided' }],
      rules: {
        name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        labelIds: [{ required: true, message: '请选择标签', trigger: 'blur' }],
        version: [{ required: true, message: '请输入版本', trigger: 'blur' }],
        groupId: [{ required: true, message: '请输入公司或组织域名', trigger: 'blur' }],
        artifactId: [{ required: true, message: '请输入实际项目名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择项目类型', trigger: 'blur' }],
      },
      mavenConstant: {},
    }
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.fetch()
        } else {
          return false
        }
      })
    },
    labelTran() {
      let labels = []
      this.labelList.forEach((item) => {
        if (this.form.labelIds?.indexOf(item.id) > -1) {
          labels.push(item)
        }
      })
      return labels
    },
    async fetch() {
      let params = {
        ...this.form,
        thirdPart: this.thirdPart,
        labels: this.labelTran(),
      }
      const fn = this.form.id ? Api.updateOtherRely : Api.addOtherRely
      let res = await fn(params)
      this.$msg.success('操作成功')
      this.close()
      this.$emit('getData')
    },

    close() {
      this.$emit('close')
    },
    async getLabel() {
      let res = await commonApi.getLabel()
      if (res.data) {
        this.labelList = res.data
      }
    },
    addLabel() {
      this.lableDrawer = true
    },

    labelChange(value) {
      this.labelList.forEach((item) => {
        if (item.id === value) {
          this.form.label = item.label
        }
      })
    },
    noBlur(scope) {},
    addTable() {
      this.form.versions.push({
        version: '',
        type: '',
        scope: '',
        versionDescription: '',
      })
    },
    delTable(index) {
      this.form.versions.splice(index, 1)
    },
    getMavenConstant() {
      Api.getMavenConstant().then(({ data }) => {
        console.log({ data })
        this.mavenConstant = data
      })
    },
  },
}
</script>
